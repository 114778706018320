.content-faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 140px 120px 140px;
}

.faq-bloc {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 1328px;
    padding-top: 64px;
}

.title-faq {
    height: 86px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap:8px;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 24px;
}

.description-faq {
    padding-left: 70px;
    padding-bottom: 12px;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 18px;
}

.image-faq {
    width: 46px;
    height: 46px;
    background-color: #FCF4F6;
    border-radius: 100px;
    padding: 8px;
}

/* ***** Dark Mode ***** */

.image-faq-dark {
    width: 46px;
    height: 46px;
    background-color: var(--fond-lment-dark);
    border-radius: 100px;
    padding: 8px;
}