.content-pro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0px;

    .content-button {
        margin-top: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }

    .case-content-one {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 32px;
        margin-left: 2px;
    }

    .case-content-two {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .case {
        width: 350px;
        height: 259px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 32px;
    }

    .case1 {
        border-top-left-radius: 15px;
        border: 1px solid #C9CFD5;
    }

    .case2 {
        border-top: 1px solid #C9CFD5;
        border-right: 1px solid #C9CFD5;
        border-bottom: 1px solid #C9CFD5;
    }

    .case3 {
        border-top-right-radius: 15px;
        border-top: 1px solid #C9CFD5;
        border-right: 1px solid #C9CFD5;
        border-bottom: 1px solid #C9CFD5;
    }

    .case4 {
        margin-left: 2px;
        border-bottom-left-radius: 15px;
        border-left: 1px solid #C9CFD5;
        border-right: 1px solid #C9CFD5;
        border-bottom: 1px solid #C9CFD5;
    }

    .case5 {
        border-right: 1px solid #C9CFD5;
        border-bottom: 1px solid #C9CFD5;
    }

    .case6 {
        border-bottom-right-radius: 15px;
        border-right: 1px solid #C9CFD5;
        border-bottom: 1px solid #C9CFD5;
    }

    /* ***** Dark Mode ***** */
    .dark-background-case {
        background-color: #3D3D3D;
    }

}

