.content-app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding: 64px 140px 120px 140px;
}

.light-image-background {
    background-image: url(../../../assets/images/pinkBackground.png);
}

.dark-image-background {
    background-image: url(../../../assets/images/blackBackground.png);
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 516px;
}

.text {
    font-size: 18px;
}