@media screen and (max-width: 1624px) {
    .whole-error {
        padding: 64px 32px 0px 32px;
    }

    .content-error {
        padding: 0px 32px 0px 32px;
    }

    .patrick-manger {
        position: relative;
        bottom: inherit;
        right: inherit;
        
    }


    .patrick-image-manger {
        position: relative;
       height: 260px !important;
       right: 0px;
       bottom: -5px
    }
}