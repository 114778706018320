@media screen and (max-width: 1624px) {
    .patrick-fee {
        position: relative;
        bottom: inherit;
        right: inherit;
        
    }


    .patrick-fee-image {
        position: relative;
       height: 260px !important;
       right: 0px;
       bottom: -54px;
       transform: scaleX(-1);
    }
}