@media screen and (max-width: 1390px) {
    .content-blog {
        padding: 64px 36px 120px;
    }

    .blog-bloc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
        margin-top: 64px;
    }
}

@media screen and (max-width: 460px) {
    .blog-card {
        width: 90%;
    }

    .image-blog {
        width: 100%;
    }
}