@media screen and (max-width: 1395px){
    .content-faq {
        padding: 64px 36px 120px;
    }
    
    .faq-bloc {
        width: 95%;
    }

    .title-faq {
        height: inherit;
    }
}