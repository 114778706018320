.patrick-fee {
    position: absolute;
    overflow: hidden;
    bottom: -64px;
    right: -185px;
    
}

.patrick-fee-image {
    height: 900px;
    position: relative;
    bottom: -183px;
    right: -40px;
}