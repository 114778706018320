.content-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 140px 120px 140px;
    position:relative;
}

.breadcrumbs .MuiBreadcrumbs-separator {
    color:var(--primary-dark);
}

.breadcrumbs .MuiBreadcrumbs-ol {
    font-family: 'Poppins-regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.whole-error {
    padding: 64px 140px;
}

.breadcrumbs {
    padding-bottom: 64px;
}

.patrick-manger {
    position: absolute;
    bottom: -68px;
    right: -68px;
}


.patrick-image-manger {
    height: 600px;
}
