.content-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 140px 120px 140px;
}

.blog-bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 64px;
}

.date {
    color:#5f6d7e;
    font-family: 'Poppins-Light';
    font-size: 14px;
    font-weight: 500;
}

.blog-card {
    width:422px
}

.link-blog {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: var(--primary-dark);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-text-decoration: none;
}

.modal-image-blog {
    width:100%;
}

.close-modal {
    text-decoration: none;
    -webkit-text-decoration: none;
    color: var(--primary-dark);
    font-size: 21px;
    font-weight: 600;
    padding: 0px 16px 16px 0px;
}

 /* ***** Dark Mode ***** */
 .date-dark {
    color:var(--text-dark);
    font-family: 'Poppins-Light';
    font-size: 14px;
    font-weight: 500;
 }