

.image-dashboard {
    padding-bottom: 64px;
}

.content-dashboard {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    padding: 64px;
}

    .contenant-image {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 736px;
    padding-top: 32px;
}

.image-slide {
height: 100%;}
