@media screen and (max-width: 1300px) {
    .content-pro {
        padding: 64px 36px 120px;

    
        .case-content-one {
            width: 500px;
        }

        .case-content-two {
            width: 500px;
        }
        
        .case1 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }

        .case2 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }

        .case3 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }

        .case4 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }
        
        .case5 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }
        
        .case6 {
            border-radius: 0px;
            border: 1px solid #C9CFD5;
        }
    }
}

@media screen and (max-width: 650px) {
    .content-pro {
        text-align: center;

        .content-button {
            flex-direction: column;
        }

        .case-content-one {
            width: 95%;
        }

        .case-content-two {
            width: 95%;
        }
    }



}