@media screen and (max-width: 1130px){
    .content-resume  {
        flex-direction: column;
        padding: 64px 36px;
        gap: 32px;

        .image-mockup {
            width: 100%;
        }
    }

}