@media screen and (max-width: 1280px) {
    .content-detail {
        padding: 64px 36px 120px;
        text-align: center;
    
    .bloc-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin: 64px 0px;
    }

    .info {
        width: inherit;
    }

    .bloc-info {
        display: flex;
        flex-direction: column;
        gap: 64px;
        padding: 0px 32px;
        background-color: #F6F6F6;
        border-radius: 10px;
        border: 1px solid #888;
        text-align:start;
    } 
    
    /* ***** Dark Mode ***** */
    .bloc-info-dark {
        display: flex;
        flex-direction: column;
        gap: 64px;
        padding: 0px 32px;
        background-color: #4f4f4f;
        border-radius: 10px;
        border: 1px solid #888;
        text-align:start;
    }

    .number-bloc-dark {
        padding: 10px;
        min-width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background-color: #d1d1d1;
        font-family: "Lobster-Regular", Helvetica;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000000;
    }
    
    .detail-title-dark {
        font-weight: 600;
        letter-spacing: -0.2px;
        margin: 0px;
        padding-bottom: 24px;
    }
}
}

@media screen and (max-width: 700px){
    .content-detail {

}
}