.download-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding: 64px 140px 120px 140px;

    .image {
        width: 548px;
        height: 562px;
        flex-shrink: 0;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 10%;
        width: 495px;
    }

    

    .download-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 24px;
        margin-top: 64px;
    }

    .button {
        padding: 11px 16px;
        border-radius: 5px;
        border: 1px solid var(--gris-1-dark)
    }
}


