.content-login {
    display: flex;
    flex-direction: row;
}

.padding-login {
    padding: 64px 140px 124px 140px;
}

.part-image {
    width: 50%;
    background-image: url(../../../assets/images/login-image.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    position: relative;
}

.part-login {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins-Regular';
}

.part-image::after {
    content: ""; 
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.part-image-content {
    position: relative;
    z-index: 2; 
}

.form-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 16px;
    width: 100%;
}

.form-label {
    font-size: 18px;
    font-weight: 400;
    color: var(--titre-dark);
}

.form-label-dark {
    font-size: 18px;
    font-weight: 400;
    color: var(--secondary-dark);
}

.form-input {
    width: calc(100% - 32px);
    padding: 0px 16px;
    height: 50px;
    border: 1px solid var(--gris-2-dark);
    border-radius: 5px;
    font-family: 'Poppins-Regular';
    background-color: white;
}

.password-input {
    width:100%;
    border-width: 0px;
    font-family: 'Poppins-Regular';
}

.password-input:focus {
    outline: 0px solid var(--primary-dark);  
}

.display-password {
    display: flex;
    flex-direction: row;
}


.form-input:focus {
    outline: 0px solid var(--primary-dark);  
}

.button-login {
    width: calc(100% - 45px);
    align-items: center;
    justify-content: center;
}

.checkbox-line {
    padding: 24px 0px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
}


.checkbox {
    display: none;
}

.checkbox-label {
    width: 30px;
    height: 30px;
    border: 1px solid var(--gris-2-dark);
    border-radius: 5px;
    position: relative; 
    cursor: pointer; 
}

.checkbox:checked + .checkbox-label::after {
    content: "✔";
    font-size: 20px;
    color: var(--primary-dark);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.password-forgot {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    text-decoration: underline;
    text-decoration-color: var(--primary-dark);
    padding-bottom: 32px;
}

.line-or {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 0px;
    width: 100%;
}

.line {
    width: 100%;
    height: 1px;
    background-color: var(--fond-arrire-plan-dark);
}

.line-dark {
    width: 100%;
    height: 1px;
    background-color: white;
}