.big-content-footer {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-around;
    padding: 120px 64px;

    .content-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        position: relative;
    }


    .footer-title {
        font-family: var(--h2-desktop-font-family);
        font-size: var(--h2-desktop-font-size);
        font-style: var(--h2-desktop-font-style);
        font-weight: var(--h2-desktop-font-weight);
        line-height: var(--h2-desktop-line-height);
        padding-bottom: 16px;
    }

    .footer-subtitle {
        font-family: "Pöppins-Regular", Helvetica;
        font-size: 25px;
        font-weight: 400;
    }

    .footer-button {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 32px 0px 64px 0px;
    }

    .footer-network {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }

    .patrick {
        position: absolute;
        bottom: -4px;
        left: 44px;
    }

    .patrick-image {
        height: 427px;
    }

    .content-footer-bloc {
        display: flex;
        flex-direction: row;
        gap: 64px;
        padding-top: 64px;
    }

    .footer-bloc {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 266px;
    }

    .footer-link {
        font-size: 20px;
        font-weight: 500;
        line-height: 50px;
        font-family: 'Poppins-Regular', Helvetica;
    }

}

.bottom-footer {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    padding: 24px 80px;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 18px;
    background-color: #f6f6f6;
}

.droit-footer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    text-decoration: none;
    -webkit-text-decoration: none;
}