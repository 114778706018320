:root {
  --fond-arrire-plan-dark: rgba(34, 34, 34, 1);
  --fond-des-champs-dark: rgba(217, 217, 217, 1);
  --fond-lment-dark: rgba(60, 60, 60, 1);
  --fond-message-dark: rgba(210, 163, 173, 1);
  --gris-1-dark: rgba(198, 193, 188, 1);
  --gris-2-dark: rgba(218, 217, 216, 1);
  --gris-3-dark: rgba(249, 249, 248, 1);
  --h1-desktop-font-family: "Lobster-Regular", Helvetica;
  --h1-desktop-font-size: 60px;
  --h1-desktop-font-style: normal;
  --h1-desktop-font-weight: 400;
  --h1-desktop-letter-spacing: 3px;
  --h1-desktop-line-height: 73px;
  --h1-mobile-font-family: "Lobster-Regular", Helvetica;
  --h1-mobile-font-size: 40px;
  --h1-mobile-font-style: normal;
  --h1-mobile-font-weight: 400;
  --h1-mobile-letter-spacing: 0px;
  --h1-mobile-line-height: normal;
  --h2-desktop-font-family: "Lobster-Regular", Helvetica;
  --h2-desktop-font-size: 40px;
  --h2-desktop-font-style: normal;
  --h2-desktop-font-weight: 400;
  --h2-desktop-letter-spacing: 2px;
  --h2-desktop-line-height: 66px;
  --h2-mobile-font-family: "Lobster-Regular", Helvetica;
  --h2-mobile-font-size: 30px;
  --h2-mobile-font-style: normal;
  --h2-mobile-font-weight: 400;
  --h2-mobile-letter-spacing: 0px;
  --h2-mobile-line-height: normal;
  --negative-dark: rgba(219, 72, 72, 1);
  --notification-dark: rgba(145, 193, 228, 1);
  --p-dark: rgba(255, 255, 255, 0.87);
  --p-font-family: "Poppins-Regular", Helvetica;
  --p-font-size: 18px;
  --p-font-style: normal;
  --p-font-weight: 400;
  --p-letter-spacing: 0px;
  --p-line-height: normal;
  --p-semibold-font-family: "Poppins-SemiBold", Helvetica;
  --p-semibold-font-size: 18px;
  --p-semibold-font-style: normal;
  --p-semibold-font-weight: 600;
  --p-semibold-letter-spacing: 0px;
  --p-semibold-line-height: 24px;
  --petite-information-font-family: "Poppins-Regular", Helvetica;
  --petite-information-font-size: 12px;
  --petite-information-font-style: normal;
  --petite-information-font-weight: 400;
  --petite-information-letter-spacing: 0px;
  --petite-information-line-height: 24px;
  --positive-dark: rgba(93, 219, 72, 1);
  --primary-dark: rgba(228, 145, 163, 1);
  --primary-dark-light: rgba(228, 145, 163, 0.6);
  --secondary-dark: rgba(255, 255, 255, 0.87);
  --shadow: 0px 4px 15px 0px rgba(245, 245, 245, 1);
  --sous-h2-desktop-font-family: "Poppins-Light", Helvetica;
  --sous-h2-desktop-font-size: 28px;
  --sous-h2-desktop-font-style: normal;
  --sous-h2-desktop-font-weight: 300;
  --sous-h2-desktop-letter-spacing: 0px;
  --sous-h2-desktop-line-height: 42px;
  --sous-h2-mobile-font-family: "Poppins-Light", Helvetica;
  --sous-h2-mobile-font-size: 22px;
  --sous-h2-mobile-font-style: normal;
  --sous-h2-mobile-font-weight: 300;
  --sous-h2-mobile-letter-spacing: 0px;
  --sous-h2-mobile-line-height: 30px;
  --sous-paragraphe-font-family: "Poppins-Regular", Helvetica;
  --sous-paragraphe-font-size: 16px;
  --sous-paragraphe-font-style: normal;
  --sous-paragraphe-font-weight: 400;
  --sous-paragraphe-letter-spacing: 0px;
  --sous-paragraphe-line-height: 24px;
  --texte-masqu-dark: rgba(99, 99, 99, 1);
  --titre-dark: rgba(44, 44, 44, 1);
  --txt: rgba(99, 99, 99, 1);
  --swiper-theme-color: rgba(228, 145, 163, 1);
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(assets/fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins-Light';
  src: url(assets/fonts/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: 'Lobster-Regular';
  src: url(assets/fonts/lobster/Lobster-Regular.ttf);
}

h1 {
  color: var(--titre-dark);
  font-family: 'Lobster-Regular';
  font-size: 60px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0px;
}

h3 {
  font-family: var(--sous-h2-desktop-font-family);
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 300;
  color: var(--titre-dark);
  margin: 0px
}

h2 {
  font-family: "Lobster-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  margin: 0px;
  padding-top: 8px;
  letter-spacing: 2px;
  line-height: 66px;
}

h4 {
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 20px;
  font-weight: 600;
  color: var(--titre-dark);
  margin: 0px;
  letter-spacing: -0.2px;
}

p {
  color: var(--titre-dark);
  font-family: 'Poppins-regular';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a {
  text-decoration: none;
  -webkit-text-decoration: none;
}

.light-text {
  color: var(--titre-dark);
}

.light-background
{
  background-color: white;
}

.gray-background {
  background-color: #3c3c3c;
}

.pink-background {
  background-color: rgb(228, 145, 163, 0.1);
}

.lobster-title {
  font-family: 'Lobster-Regular';
  font-size: var(--h1-desktop-font-size);
  font-weight: var(--h1-desktop-font-weight);
}

.gap-32 {
  padding-bottom: 32px;
}

.gap-64 {
  padding-bottom: 64px;
}

.center-text {
  text-align: center;
}

.no-decoration {
  text-decoration: none;
  -webkit-text-decoration: none;
  color:initial;
}

.lobster {
  font-family: 'Lobster-Regular';
}

.pink {
  color: var(--primary-dark);

}

 /* ***** Dark Mode ***** */
.dark-background {
  background-color: #222;
}

.dark-text {
    color: var(--secondary-dark);
}

@media screen and (max-width: 780px) {
  .lobster-title {
    text-align: center;
  }

}