.content-offer {
    padding: 64px 140px;
    background-image: url(../../../assets/images/BackgroundTattoo.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.h1-lobster {
    font-family: 'Lobster-Regular';
    font-size: var(--h1-desktop-font-size);
    font-weight: var(--h1-desktop-font-weight);
    color: var(--secondary-dark);
    margin: 0px;
    letter-spacing: -0.2px;
}

.bloc-offer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    letter-spacing: var(--h1-desktop-letter-spacing);
}

.fiche-produit {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    padding: 32px;
    margin: 64px 0px;
    background-color: #fcf4f6;
    border-radius: 5px;
}

.first-line-fiche-produit {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.size-produit {
    font-size: 30px;
}

.size-offre {
    padding-top: 8px;
    font-size: var(--sous-h2-mobile-font-size);
    letter-spacing: var(--sous-h2-mobile-letter-spacing);
    font-weight: var(--sous-h2-mobile-font-size);
}

.content-fiche-produit {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
}

.element-fiche {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0;
}