@media screen and (max-width: 1540px) {
    .big-content-footer {
        padding: 120px 32px;

        flex-direction: column;

        .content-footer {
            align-items: center;
        }

        .footer-subtitle {
            text-align: center;
        }

        .content-footer-bloc {
            justify-content: center;
        }
    }
}

@media screen and (max-width: 964px) {
    .big-content-footer {
        .content-footer-bloc {
            flex-direction: column;
            align-items: center;
        }

        .footer-bloc {
            gap: 16px;
            text-align: center;
            padding-bottom: 34px;
        }

        .content-footer-bloc {
            gap:32px;
        }
    }

    .bottom-footer {
        padding: 24px 32px;
        flex-direction: column;
    }
}