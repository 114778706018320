@media screen and (max-width: 1300px) {
    .download-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
        padding: 64px 36px 120px;

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            width: 495px;
        }

        .download-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            margin-top: 64px;
        }

        .button {
            width: 100%;
            padding: 11px 16px;
            border-radius: 5px;
            border: 1px solid var(--gris-1-dark)
        }
    }

    .image {
        width: 548px;
        height: 562px;
        flex-shrink: 0;
    } 

}

@media screen and (max-width: 700px) {

    .download-content {
        .info {
            width: inherit;
        }

        .download-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            margin-top: 64px;
        }

        .button {
            padding: 11px 16px;
            border-radius: 5px;
            border: 1px solid var(--gris-1-dark);
            width: inherit;
        }

        .image {
            width: 100%;
            height: auto;
        }
    }
}