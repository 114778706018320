@media screen and (max-width: 980px) {
    .content-app {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
        padding: 64px 36px 120px;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: inherit;
    }

}