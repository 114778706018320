.content-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 140px 120px 140px;


.bloc-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 64px 0px;
}

.bloc {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.bloc-title {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.number-bloc {
    padding: 10px;
    min-width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #FCF4F6;
    font-family: "Lobster-Regular", Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bloc-info {
    display: flex;
    flex-direction: row;
    gap: 64px;
    padding: 0px 64px;
    background-color: #F6F6F6;
    border-radius: 10px;
    border: 1px solid #888;
}

.info {
    display: flex;
    flex-direction: column;
    margin: 32px;
    width: 33%;
}

.icon-info {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    margin-bottom: 16px;
}

.detail-title {
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #000000;
    margin: 0px;
    padding-bottom: 24px;
}

.detail-text {

    margin: 0px;
}

/* ***** Dark Mode ***** */

.number-bloc-dark {
    padding: 10px;
    min-width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #d1d1d1;
    font-family: "Lobster-Regular", Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000000;
}

.bloc-info-dark {
    display: flex;
    flex-direction: row;
    gap: 64px;
    padding: 0px 64px;
    background-color: #4f4f4f;
    border-radius: 10px;
    border: 1px solid #888;
}

.detail-title-dark {
    font-weight: 600;
    letter-spacing: -0.2px;
    margin: 0px;
    padding-bottom: 24px;
}
}