@media screen and (max-width: 1610px) {
    .contenant-header {
        padding: 32px 5%;

        .logo-header {
            padding: 0px 10%;
        }
    }   
    
    .drawer-content {
        display: flex;
        align-items: flex-start;
        padding: 24px 36px 24px 36px;
        min-width: 320px;
        width: 40%;
        height: 100%;
    }

    .drawer-content-dark {
        display: flex;
        align-items: flex-start;
        padding: 24px 36px 24px 36px;
        min-width: 320px;
        width: 40%;
        height: 100%;
        background-color: var(--fond-arrire-plan-dark) !important;
    }

    .head-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-family: 'Poppins-Regular';
    }

    .toggle {
        padding: 8px 10px 7px 10px;
        border-radius: 50px;
        border: 0px;
        background-color: var(--gris-3-dark);
    }

    .toggle-dark {
        padding: 10px 10px 7px 10px;
        border-radius: 50px;
        border: 0px;
        background-color: var(--fond-lment-dark);
    }

    .burger {
        width: 30px;
        height: 30px;
    }

    .link-header-menu {
        padding: 24px 0px 32px 20px;
        font-family: 'Poppins-Regular';
    }
    
    .text-link-header {
        height: 44px;
        color: var(--titre-dark);
        font-size: 18px;
        font-weight: 400;

        a {
            text-decoration: none;
            -webkit-text-decoration: none;
            color: var(--titre-dark);

        }
    }

    .text-link-header-dark {
        height: 44px;
        color: white;
        font-size: 18px;
        font-weight: 400;

        a {
            text-decoration: none;
            -webkit-text-decoration: none;
            color: white;

        }
    }

    .button-header-menu {
        display: flex;
        flex-direction: column;
        gap: 35px;
        font-family: 'Poppins-Regular';
    }

    .button-menu {
        display: inherit;
        min-width: 288px;
        font-size: 16px;
        padding: 10px 0px !important;
    }
    


}