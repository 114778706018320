.contenant-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 32px 140px 32px 140px;
    font-family: 'Poppins-Regular';
    position: sticky;
    top: 0;
    z-index: 100;

    a {
        text-decoration: none;
        -webkit-text-decoration: none;
        color: var(--titre-dark)
    }


    .logo {
        width: 155px;
        height: 50px;
    }


    .logo-header {
        padding-right: 10%;
    }

    .link-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        min-width: 482px;
    }

    .text-link-header {
        padding: 10px;
    }

    .button-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-width: 20%;
        margin-left: 40px;

        .button {
            margin-left: 24px;
        }
    }

    .pink {
        padding: 10px 24px 10px 24px;
        background-color: var(--primary-dark);
        border-radius: 10px;
        font-family: 'Poppins-semibold';
        min-width: 90px;
        color: var(--titre-dark)
    }

    .toggle {
        padding: 8px 10px 7px 10px;
        border-radius: 50px;
        border: 0px;
        background-color: var(--gris-3-dark);
    }

    .toggle-dark {
        padding: 10px 10px 7px 10px;
        border-radius: 50px;
        border: 0px;
        background-color: var(--fond-lment-dark);
    }

    /* ***** Dark Mode ***** */
    .dark-text {
        a {
            color: var(--secondary-dark);
        }
    }


}

.shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

